import React from "react";
import { Box, Button, Flex } from "@reinventa-formacion/ui-core";
import Cookies from "universal-cookie";
import { GDPR_GTM_COOKIE_BANNER } from "../constants";

const cookies = new Cookies();

export function AcceptCookiesBanner() {
  const [hide, setHide] = React.useState(true);

  function accept(accepted) {
    const now = new Date();
    cookies.set(GDPR_GTM_COOKIE_BANNER, accepted.toString(), {
      expires: new Date(now.setFullYear(now.getFullYear() + 1)),
    });
    setHide(true);
  }

  React.useEffect(() => {
    if (
      cookies.get(GDPR_GTM_COOKIE_BANNER) === null ||
      cookies.get(GDPR_GTM_COOKIE_BANNER) === undefined
    ) {
      setHide(false);
    }
  }, []);

  if (hide) return null;

  return (
    <Flex
      sx={{
        position: "fixed",
        zIndex: 99999,
        bottom: 0,
        left: 0,
        width: "100%",
        p: 2,
        textAlign: "center",
        backgroundColor: "inverseBackground",
        justifyContent: "center",
        flexDirection: ["column", "column", "row"],
      }}
    >
      <Flex
        sx={{ alignItems: "center", color: "inverseText", fontSize: [1, 2] }}
      >
        Este website utiliza cookies para crear estadísticas y mejorar la
        calidad del website. Tú puedes aceptar o rechazarlas usando los
        siguientes botones:
      </Flex>
      <Box sx={{ minWidth: ["100%", "385px"], pt: [3, 0] }}>
        <Button
          sx={{ ml: [0, 4], mr: 2 }}
          variant="primary"
          onClick={() => accept(true)}
        >
          Aceptar
        </Button>
        <Button onClick={() => accept(false)}>Rechazar</Button>
      </Box>
    </Flex>
  );
}
