import React from "react";
import {
  Section as LeanSection,
  ThemeProvider,
  useTheme,
} from "@reinventa-formacion/ui-core";
import { BoxProps, As } from ".";
import { Container, Grid, Box } from "./";
import { H2, H3, P } from "../display";
import Image from "../display/Image";
import { Card } from "./Card";
import Cta from "../navigation/Cta";

interface Props {
  top?: boolean;
}

export function Section<T extends As = "section">({
  top,
  sx,
  variant,
  ...rest
}: BoxProps<T, Props>) {
  const theme: any = useTheme();
  const section = (
    <LeanSection
      {...(rest as any)}
      sx={{ ...sx, px: 0, ...(top ? { mt: ["-90px"] } : {}) }}
      variant={variant || "default"}
    />
  );

  return variant === "secondary" ? (
    <ThemeProvider
      theme={{
        colors: { text: theme.colors.inverseText },
      }}
    >
      {section}
    </ThemeProvider>
  ) : (
    section
  );
}

export function PageSection({
  fluidImage = null,
  altImage = null,
  imageLeft = true,
  title,
  subtitle = "",
  text,
  variant = null,
  callToAction = null,
}) {
  let imageCol;
  if (fluidImage) {
    imageCol = (
      <Box
        sx={{
          gridColumn: ["1/ -1", imageLeft ? "1/ 7" : "7/ -1"],
          mr: imageLeft ? [0, 6] : 0,
          mb: [4, 0],
          ...(!imageLeft ? { gridRow: 1 } : {}),
        }}
      >
        <Card sx={{ bg: "background" }}>
          <Image fluid={fluidImage} alt={altImage} />
        </Card>
      </Box>
    );
  }

  return (
    <Section variant={variant}>
      <Container>
        <Grid columns={12}>
          {imageLeft && imageCol}
          <Box
            sx={{
              gridColumn: [
                "1/ -1",
                imageCol && imageLeft
                  ? "7/ -1"
                  : imageCol && !imageLeft
                  ? "1/ 7"
                  : "1/-1",
              ],
            }}
          >
            {title && <H2 sx={{ mt: 0 }}>{title}</H2>}

            {subtitle && <H3>{subtitle}</H3>}

            {text && <P>{text}</P>}

            {callToAction && <Cta sx={{ mt: 3 }} data={callToAction} />}
          </Box>
          {!imageLeft && imageCol}
        </Grid>
      </Container>
    </Section>
  );
}

export default Section;
