import React from "react";
import { ThemeProvider } from "@reinventa-formacion/ui-core";
import { useStaticQuery, graphql } from "gatsby";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
} from "@reinventa-formacion/ui-icons";

import { contact } from "../../api";
import { DARK_GREY } from "../../config/theme";
import { Grid, Container, Box, Ul, Li, Flex } from ".";
import { Section } from "./Section";

import { H3, P, Spinner } from "../display";
import Link from "../navigation/Link";
import {
  Form,
  Field,
  Input,
  Button,
  composeValidators,
  mustBeEmail,
  onlyPositiveNumbers,
  required,
} from "../form";
import CTA from "../navigation/Cta";

const Footer = () => {
  const { footer } = useStaticQuery(graphql`
    query FooterQuery {
      footer: sanityNavigationMenu(name: { eq: "footer" }) {
        items {
          title
          link
          landingPage {
            slug {
              current
            }
          }
        }
      }
    }
  `);

  const half = Math.ceil(footer.items.length / 2);
  const siteMapFirstHalf = footer.items.slice(0, half);
  const siteMapSecondHalf = footer.items.slice(-half);

  return (
    <ThemeProvider
      theme={{
        colors: {
          text: "#fff",
        },
        forms: {
          input: {
            color: DARK_GREY,
          },
          error: {
            color: DARK_GREY,
          },
        },
      }}
    >
      <Section
        sx={{
          bg: "secondary",
          mb: 0,
          pb: 0,
        }}
        as="footer"
      >
        <Container sx={{ pt: 10, pb: 8 }}>
          <Grid columns={12}>
            <Box sx={{ gridColumn: ["1/ -1", "1 / 6"] }}>
              <H3 sx={{ mt: 0 }}>
                <div id="contact-form" />
                Contacta con nosotros
              </H3>
              <Form
                onSubmit={async (values: any) => {
                  try {
                    await contact({
                      subject: "👋 Contacto de ReinventaFormacion",
                      message: `
                      Email de contacto: ${values.email}<br />
                      Nombre completo:  ${values.name}<br />
                      Mensaje: ${values.message}
                      `,
                    });
                  } catch (error) {
                    // TODO
                  }
                }}
              >
                {({ submitSucceeded, submitting }) => {
                  return submitSucceeded ? (
                    <H3>
                      Mensaje enviado. Te responderemos tan rápido como nos sea
                      posible.{" "}
                    </H3>
                  ) : (
                    <>
                      <Field
                        component={Input}
                        validate={required}
                        label="Nombre completo:"
                        name="name"
                        placeholder="Escribe tu nombre aquí"
                      />
                      <Field
                        component={Input}
                        validate={composeValidators(mustBeEmail, required)}
                        label="Tu correo electrónico:"
                        name="email"
                        placeholder="direccion@ejemplo.com"
                      />
                      <Field
                        component={Input}
                        validate={composeValidators(
                          onlyPositiveNumbers,
                          required
                        )}
                        label="Tu teléfono:"
                        name="phone"
                      />
                      <Field
                        component={Input}
                        as="textarea"
                        validate={composeValidators(required)}
                        label="Mensaje:"
                        name="message"
                        placeholder="Escribe tu mensaje aquí"
                      />
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={submitting}
                        className="newsletter-submit-button"
                      >
                        {submitting ? <Spinner /> : "Enviar"}
                      </Button>
                    </>
                  );
                }}
              </Form>
              <P>
                Copyright &copy; {`${new Date().getFullYear()}`},
                ReinventaFormacion.
              </P>
            </Box>
            <Box sx={{ gridColumn: ["1/ -1", "7 / -1"] }}>
              <H3 sx={{ mt: 0 }}>Enlaces del sitio</H3>
              <Flex>
                <Ul variant="unstyled" sx={{ mt: 0, mb: 2, flex: 1 }}>
                  {siteMapFirstHalf.map((item) => (
                    <Li key={item.title}>
                      <CTA data={item} className="siteLink" />
                    </Li>
                  ))}
                </Ul>
                <Ul variant="unstyled" sx={{ mt: 0, mb: 2, flex: 1 }}>
                  {siteMapSecondHalf.map((item) => (
                    <Li key={item.title}>
                      <CTA data={item} className="siteLink" />
                    </Li>
                  ))}
                </Ul>
              </Flex>

              <H3>Conecta con nosotros</H3>
              <Ul variants={["unstyled", "inline"]} sx={{ mt: 0, mb: 2 }}>
                {[
                  //   {
                  //     to: "",
                  //     title: "React GraphQL Academy Twitter",
                  //     icon: TwitterIcon,
                  //   },
                  {
                    to: "https://www.instagram.com/reinventaformacion/",
                    title: "ReinventaFormacion Instagram",
                    icon: InstagramIcon,
                  },
                  {
                    to:
                      "https://www.facebook.com/ReinventaFormaci%C3%B3n-2365860353738924",
                    title: "React GraphQL Academy Facebook",
                    icon: FacebookIcon,
                  },
                  {
                    to: "https://www.linkedin.com/company/65529049",
                    title: "ReinventaFormacion LinkedIn",
                    icon: LinkedinIcon,
                  },
                ].map(({ to, title, icon: Icon }) => (
                  <Li key={to}>
                    <Link title={title} to={to} className="footer-follow-us">
                      <Icon />
                    </Link>
                  </Li>
                ))}
              </Ul>
            </Box>
          </Grid>
        </Container>
      </Section>
    </ThemeProvider>
  );
};

export default Footer;
