import React from "react";

import Link, { LinkButton } from "./Link";
import { getPostPath } from "../blog/utils";

function Cta({ data, ...rest }) {
  const callToActionUrl = data?.landingPage?.slug?.current
    ? data?.landingPage?.slug?.current
    : data?.blogPost?.slug?.current
    ? getPostPath(data?.blogPost?.slug?.current)
    : data?.link;
  const callToActionTitle = data?.title;
  const CallToActionElement = data?.kind === "button" ? LinkButton : Link;
  const callToActionButtonVariant = data?.buttonVariant || "default";

  return callToActionUrl ? (
    <CallToActionElement
      {...rest}
      variant={callToActionButtonVariant}
      to={
        callToActionUrl.indexOf("/") === 0
          ? callToActionUrl
          : `/${callToActionUrl}`
      }
    >
      {callToActionTitle}
    </CallToActionElement>
  ) : null;
}

export default Cta;
