import React from "react";
import BlockContent from "@sanity/block-content-to-react";

import { H2, H3, H4, H5, P, Span } from "./components/display";
import { Ul, Li } from "./components/layout";
import Tick from "./components/icons/Tick";

export function getBlockContent(blocks) {
  return <BlockContent blocks={blocks} serializers={serializers} />;
}

export const serializers = {
  marks: {
    // link: ({ mark: { href }, children }) => (
    //   <BlogPostLink to={href} children={children} />
    // ),
    // internalLink: ({ mark = {}, children }) => {
    //   return (
    //     <BlogPostLink to={internalLinkTo({ mark })}>{children}</BlogPostLink>
    //   );
    // },
  },
  list: ({ children }) => (
    <Ul variant="unstyled" sx={{ ml: 7 }} children={children} />
  ),
  listItem: ({ children = {} }) => (
    <Li sx={{ position: "relative" }}>
      <Tick
        width={20}
        sx={{ position: "absolute", left: "-30px", top: "8px" }}
      />
      {children}
    </Li>
  ),
  hardBreak: null,
  types: {
    block: ({ children, node }) => {
      const style = node.style || "normal";
      let formatedChildren;
      switch (style) {
        case "h2":
          return <H2 children={children} />;
        case "h3":
          return <H3 children={children} />;
        case "h4":
          return <H4 children={children} />;
        case "h5":
          return <H5 children={children} />;
        // case 'blockquote':
        //   return <Blockquote children={children} />;
        default:
          formatedChildren =
            children &&
            children.reduce &&
            children.reduce((acc, curr) => {
              const element = removeCarriageReturn(curr);
              if (element) {
                acc.push(element);
              }

              return acc;
            }, []);

          return formatedChildren && formatedChildren.length ? (
            <P children={formatedChildren} />
          ) : null;
      }
    },
    span: ({ node }) => <Span children={node.children} />,
  },
};

function removeCarriageReturn(text) {
  if (text && typeof text === "string") {
    return text.replace(/[\n\r]+/g, "");
  } else {
    return text;
  }
}

function getOffsetDate(utcDate, utcOffset = 60, offsetDays = 0) {
  if (!utcDate) {
    return null;
  }
  const targetTime = new Date(utcDate),
    minutesToMilliseconds = 60000,
    minutesToDays = 1440;
  const localOffsetInMs =
    targetTime.getTimezoneOffset() * minutesToMilliseconds;
  const utcOffsetInMs = utcOffset * minutesToMilliseconds;
  const dayOffset = offsetDays * minutesToDays * minutesToMilliseconds;
  const offsetDate = new Date(
    targetTime.getTime() + dayOffset + localOffsetInMs + utcOffsetInMs
  );

  return offsetDate;
}

function formatDate(date, format) {
  if (!date) {
    return "";
  }
  const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    D = date.getDate() || "",
    MMM = months[date.getMonth()] || "",
    MM = date.getMonth() + 1,
    YYYY = date.getFullYear() || "";
  switch (format) {
    case "D MMM":
      return `${D} ${MMM}`;
    case "MMM":
      return `${MMM}`;
    case "D MMM 'YYYY":
      return `${D} ${MMM} ${YYYY}`;
    case "D/MM/YYYY":
      return `${D}/${MM}/${YYYY}`;
    default:
      return "";
  }
}

export const formatUTC = (
  utcDate,
  utcOffset = 60,
  format = "D MMM 'YYYY",
  offsetDays = 0
) => formatDate(getOffsetDate(utcDate, utcOffset, offsetDays), format);
