import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default function SEO({
  description,
  imageUrl = null,
  meta = [],
  author = null,
  title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang: site?.siteMetadata?.lang || "en",
      }}
      title={title}
      meta={[
        ...(imageUrl
          ? [
              {
                name: "twitter:image",
                content: imageUrl,
              },
              {
                name: "og:image",
                content: imageUrl,
              },
            ]
          : []),
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  );
}
