const BASE_URL = `${
  process.env.NODE_ENV === "development" ? "" : "https://reinventaformacion.com"
}`;
const BASE_URL_PATH = `${BASE_URL}/.netlify/functions`;

const defaultOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
};

export function contact({ message, subject }) {
  return fetch(`${BASE_URL_PATH}/contact`, {
    ...defaultOptions,
    body: JSON.stringify({ message, subject }),
  });
}
