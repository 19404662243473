export {
  Box,
  Flex,
  Container,
  Grid,
  Ul,
  Li,
  BoxProps,
  As,
  Heading,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
} from "@reinventa-formacion/ui-core";
