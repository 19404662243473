export {
  Heading,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  P,
  Blockquote,
  SVG,
  Image,
  Span,
  Spinner,
  Tag,
} from "@reinventa-formacion/ui-core";
