import React, { useState } from "react";
import styled from "styled-components";

import Link from "../../Link";
import ReinventaLogo from "../../../logos/ReinvantaLogo";
import ToggleButton from "./ToggleButton";

const Item = (props) => <Link {...props} />;

export const PhoneMenuItem = styled(Item)`
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
`;
PhoneMenuItem.displayName = "PhoneMenuItem";

export const Overlay = styled.div`
  top: 0;
  left: 0;
  z-index: 99999;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: opacity 0.3s ease 0s;
`;
Overlay.displayName = "Overlay";

export const MenuContent = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  right: inherit;
  z-index: 1100;
  width: ${({ width }) => width}px;
  margin-left: ${({ isOpen, width }) => (isOpen ? 0 : `-${width}px`)};
  height: 100%;
  transition: all 0.5s ease 0s;
  background: white;
  padding: 1.3em 1.5em 0;
  box-sizing: border-box;
  overflow: auto;
  a:first-child {
    margin-bottom: 15px;
  }
`;

const PhoneMenu = ({ width, items, defaultIsOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  function toggleMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <React.Fragment>
      <ToggleButton toggleMenu={toggleMenu} isOpen={isOpen} />
      {isOpen && <Overlay onClick={toggleMenu} />}
      <MenuContent width={width} isOpen={isOpen}>
        <ReinventaLogo sx={{ mb: 5, display: "inline-block" }} />
        {items.map(({ landingPage, link, title }) => {
          const to = landingPage ? `/${landingPage.slug.current}` : link;

          return (
            <PhoneMenuItem onClick={toggleMenu} key={to} to={to}>
              {title}
            </PhoneMenuItem>
          );
        })}
      </MenuContent>
    </React.Fragment>
  );
};

PhoneMenu.defaultProps = {
  width: 275,
};

export default PhoneMenu;
