import React from "react";
import { SVG } from "../display";

const ReinventaLogo = (props) => (
  <SVG width={160} viewBox="0 0 521.333 152" {...props}>
    <g transform="matrix(1.33333 0 0 -1.33333 0 125.333)">
      <g fillOpacity="1" stroke="none" transform="scale(.1)">
        <path
          fill="#265199"
          fillRule="evenodd"
          d="M1688.67 394.801c27.62-5.821 57.27-8 88.95-6.641 31.64 1.41 63.55 6.141 95.82 14.141-24.81-4.961-48.75-7.18-71.8-6.641-23.16.469-44.1 3.75-62.77 10-21.72 7.539-39.38 18.481-52.97 32.86-13.6 14.332-22.38 31.05-26.37 50.152-4.33 23.519-.62 47.578 11.25 72.269 11.84 24.68 31.37 48.75 58.56 72.258a327.248 327.248 0 0021.09 16.84 507.933 507.933 0 0023.59 16.25l41.45-84.301 81.8 237.742L1639.84 940l45.71-93.281c-3.99-2.457-9.14-5.899-15.55-10.43-6.25-4.379-12.81-9.18-19.65-14.379-6.99-5.07-13.4-10-19.26-14.84-5.82-4.57-10.47-8.199-13.9-10.941-29.46-25.738-52.7-50.309-69.69-73.629-16.99-23.629-29.18-45.82-36.52-66.559-7.5-20.863-11.41-40.043-11.72-57.582-.35-17.5 1.36-33.238 5.08-47.23 6.68-24.957 18.71-48.078 36.09-69.527 17.38-21.09 38.79-39.262 64.26-54.372 25.43-15.199 53.43-26.019 83.98-32.429m123.83 512.347c78.63 20.192 146.52 25.942 203.67 17.262 57.31-8.512 101.29-31.051 131.95-67.648 16.72-20.391 28.4-43.711 34.97-70.082 6.6-26.059 8.08-53.52 4.45-82.418-3.67-28.91-12.46-57.543-26.37-85.903-6.6-13.437-15.62-27.968-27.15-43.589-11.52-15.629-25.62-31.532-42.26-47.7-16.64-15.859-35.78-30.98-57.38-45.351 35.54 27.89 62.77 56.332 81.71 85.312 18.95 29.02 31.1 57.149 36.45 84.379 5.66 27.219 6.13 52.18 1.44 74.84-4.72 22.379-12.73 40.941-24.06 55.738-20.15 26.41-46.33 45.434-78.51 57.071-32.46 11.679-71.37 13.871-116.72 6.601l-122.19 61.488"
        ></path>
        <path
          fill="#45b042"
          fillRule="evenodd"
          d="M2101.99 770.699c-13.28 13.36-28.98 23.321-47.22 29.922-18.21 6.879-37.66 10.469-58.29 10.75-20.5.348-41.13-2.269-61.87-7.973l-32.85-84.378c41.21 8.992 77.5 10.98 108.86 6.058 31.37-4.957 56.26-17.027 74.61-36.25 7.7-7.848 13.4-18.359 17.19-31.488 3.95-13 4.26-28.238.98-45.699-3.01-17.5-11.21-36.801-24.49-58.012-13.17-21.051-32.97-43.629-59.34-67.77 30.74 23.711 54.88 47.231 72.46 70.512 17.54 23.008 29.81 45.391 36.8 67.149 7.11 21.558 10 41.75 8.71 60.539-.98 18.75-4.73 35.472-11.25 50.203-6.52 14.718-14.61 26.867-24.3 36.437"
        ></path>
        <path
          fill="#f8b545"
          fillRule="evenodd"
          d="M1888.52 674.059c26.4 9.261 51.13 14.179 74.25 14.73 22.97.742 43.09-1.84 60.39-7.66 17.27-6.09 30.51-14.41 39.73-24.957 11.37-13.633 17.27-28.242 17.62-43.832.23-15.461-3.79-31.051-12.07-46.75-8.28-15.711-19.46-30.512-33.56-44.461-14.1-14.18-29.96-26.559-47.5-37.109 20.47 17.859 34.26 33.359 41.33 46.449 7.27 13.242 10.12 24.73 8.52 34.453-1.64 9.726-5.39 18.129-11.29 25.156-7.03 9.262-18.6 15.863-34.77 19.762-16.09 4.18-35.7 4.609-58.71 1.18-23.01-3.36-48.32-11.61-75.94-24.61l32 87.649"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M36.879 159.02H80.62c23.758 0 41.098 4.609 52.039 13.91 11.012 9.371 16.559 23.281 16.559 41.718 0 18.442-5.59 31.872-16.719 40.313-11.051 8.437-28.98 12.66-53.75 12.66H36.879zm0-31.25V6.84H0v292.648h80.621c36.328 0 63.207-6.949 80.629-20.777 17.379-13.75 26.09-34.531 26.09-62.34 0-38.75-19.531-64.922-58.59-78.441L209.059 6.84h-43.118l-70.78 120.93zm307.031-125c-32.93 0-58.75 9.93-77.5 29.851-18.75 20-28.129 47.5-28.129 82.5 0 35.188 8.668 63.199 26.098 84.059 17.5 20.941 41.133 31.41 70.93 31.41 27.582 0 49.613-8.91 66.101-26.719 16.442-17.82 24.68-41.961 24.68-72.351v-21.719H275.469c.703-25 7.07-44.02 19.062-57.032 11.949-12.93 28.989-19.37 51.09-19.37 11.649 0 22.738 1.011 33.289 3.12 10.621 2.07 23 6.141 37.18 12.192V16.988c-12.18-5.226-23.668-8.867-34.371-10.937C371.09 3.87 358.48 2.77 343.91 2.77zM335 201.21c-17.301 0-30.941-5.59-40.941-16.723-10-11.047-15.938-26.398-17.809-46.09h111.871c-.23 20.512-4.961 36.09-14.211 46.723-9.18 10.699-22.148 16.09-38.91 16.09z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M517.969 6.84h-36.09v219.84h36.09zm-38.91 279.211c0 8.008 2.031 13.789 6.101 17.347 4.059 3.633 9.102 5.473 15.149 5.473 5.632 0 10.511-1.84 14.691-5.473 4.141-3.558 6.25-9.339 6.25-17.347 0-7.813-2.109-13.59-6.25-17.34-4.18-3.75-9.059-5.621-14.691-5.621-6.047 0-11.09 1.871-15.149 5.621-4.07 3.75-6.101 9.527-6.101 17.34z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M737.27 6.84V147.3c0 17.898-3.989 31.25-11.879 40-7.93 8.75-20.391 13.129-37.34 13.129-22.5 0-38.871-6.25-49.071-18.75-10.23-12.5-15.308-32.809-15.308-60.942V6.84h-36.094v219.84h29.063l5.308-30h2.031c6.758 10.699 16.29 19.058 28.598 25 12.262 5.941 25.82 8.91 40.621 8.91 26.961 0 46.961-6.57 60-19.692 13.129-13.046 19.692-33.359 19.692-60.937V6.84z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M889.879 6.84l-83.277 219.84h38.597L891.91 97.148c9.141-26.058 15.039-45.468 17.66-58.277h1.559c.391 1.75.859 3.75 1.41 5.93a179.482 179.482 0 002.191 7.187c.821 2.582 5.782 17.66 14.84 45.16l46.879 129.532h38.431L931.289 6.84z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M1143.09 2.77c-32.93 0-58.75 9.93-77.5 29.851-18.75 20-28.13 47.5-28.13 82.5 0 35.188 8.67 63.199 26.09 84.059 17.5 20.941 41.14 31.41 70.94 31.41 27.58 0 49.61-8.91 66.1-26.719 16.44-17.82 24.68-41.961 24.68-72.351v-21.719h-150.62c.7-25 7.07-44.02 19.06-57.031 11.95-12.93 28.99-19.372 51.09-19.372 11.65 0 22.74 1.012 33.29 3.122 10.62 2.07 23 6.14 37.18 12.19V16.989c-12.18-5.226-23.67-8.867-34.37-10.937-10.63-2.18-23.24-3.281-37.81-3.281zm-8.91 198.441c-17.3 0-30.94-5.59-40.94-16.723-10-11.047-15.94-26.398-17.81-46.09h111.87c-.23 20.512-4.96 36.09-14.21 46.723-9.18 10.699-22.15 16.09-38.91 16.09z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M1430.74 6.84V147.3c0 17.898-3.98 31.25-11.87 40-7.93 8.75-20.39 13.129-37.35 13.129-22.5 0-38.86-6.25-49.06-18.75-10.23-12.5-15.31-32.809-15.31-60.942V6.84h-36.1v219.84h29.07l5.31-30h2.03c6.76 10.699 16.29 19.058 28.59 25 12.27 5.941 25.83 8.91 40.63 8.91 26.95 0 46.95-6.57 60-19.692 13.12-13.046 19.69-33.359 19.69-60.937V6.84z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M1608.36 32.3c4.69 0 10.16.47 16.41 1.41 6.25.938 10.97 1.95 14.21 3.13V9.18c-3.36-1.48-8.51-2.852-15.46-4.22-7-1.44-14.14-2.19-21.41-2.19-42.93 0-64.38 22.66-64.38 67.968v127.813h-31.09v17.347l31.41 14.372 14.53 46.878h21.4V226.68h63.44v-28.129h-63.44V71.68c0-12.73 3.01-22.461 9.07-29.22 6.01-6.8 14.45-10.16 25.31-10.16z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M1818.83 6.84l-7.03 31.25h-1.57c-11.05-13.75-21.99-23.09-32.81-27.969-10.86-4.89-24.53-7.351-41.09-7.351-21.68 0-38.67 5.671-50.94 17.03-12.3 11.329-18.44 27.391-18.44 48.13 0 44.57 35.2 67.89 105.63 70l37.19 1.41v12.961c0 16.758-3.6 29.109-10.79 37.039-7.18 7.89-18.75 11.871-34.68 11.871-11.57 0-22.58-1.801-32.97-5.313-10.31-3.437-20-7.308-29.06-11.558l-11.1 27.031c11.13 5.93 23.21 10.539 36.25 13.899 13.13 3.32 26.1 5 38.91 5 26.56 0 46.29-5.891 59.22-17.649 12.89-11.801 19.37-30.473 19.37-56.101V6.84zm-74.38 25c20.2 0 36.02 5.39 47.5 16.25 11.57 10.93 17.35 26.398 17.35 46.398v19.852l-32.35-1.41c-25.23-.942-43.59-4.961-55.15-12.032-11.57-7.109-17.35-18.238-17.35-33.437 0-11.48 3.48-20.27 10.47-26.41 7.07-6.172 16.92-9.211 29.53-9.211z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M1954.77 6.84h-36.88v292.648h163.59v-32.34h-126.71v-103.75h118.9v-32.187h-118.9z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M2316.84 117.148c0-35.937-9.07-64.027-27.19-84.218-18.13-20.121-43.24-30.16-75.31-30.16-19.93 0-37.5 4.609-52.82 13.91-15.31 9.261-27.14 22.582-35.47 40-8.24 17.379-12.34 37.539-12.34 60.468 0 35.704 8.91 63.512 26.72 83.442 17.89 20 43.01 30 75.31 30 30.94 0 55.51-10.16 73.75-30.469 18.21-20.32 27.35-47.973 27.35-82.973zm-165.94 0c0-27.617 5.39-48.597 16.25-62.968 10.94-14.38 27.03-21.559 48.28-21.559 20.94 0 36.87 7.18 47.81 21.559 10.94 14.37 16.41 35.351 16.41 62.968 0 27.582-5.55 48.364-16.56 62.34-10.94 13.953-27.04 20.942-48.29 20.942-42.61 0-63.9-27.77-63.9-83.282z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M2476.64 230.59c9.69 0 18.28-.789 25.78-2.352l-4.37-33.277c-8.13 1.879-16.06 2.809-23.75 2.809-11.68 0-22.5-3.239-32.5-9.68-9.92-6.371-17.66-15.16-23.28-26.41-5.55-11.25-8.29-23.75-8.29-37.5V6.84h-36.09v219.84h29.69l3.9-40.309h1.57c8.43 14.57 18.36 25.539 29.84 32.969 11.56 7.5 24.06 11.25 37.5 11.25z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M2823.59 6.84v141.558c0 34.692-14.92 52.032-44.68 52.032-20.43 0-35.43-5.942-45-17.809-9.61-11.883-14.38-30.082-14.38-54.531V6.84h-35.94v141.558c0 17.262-3.67 30.231-10.93 38.903-7.19 8.75-18.56 13.129-34.07 13.129-20.31 0-35.15-6.211-44.53-18.59-9.37-12.43-14.06-32.781-14.06-61.102V6.84h-36.09v219.84h29.06l5.31-30h2.03c6.25 10.621 15.04 18.91 26.41 24.84 11.44 6.019 24.26 9.07 38.44 9.07 34.45 0 56.91-12.27 67.34-36.719h2.03c6.95 11.641 16.56 20.66 28.75 27.027 12.27 6.442 26.17 9.692 41.72 9.692 24.77 0 43.32-6.41 55.62-19.219 12.39-12.82 18.6-33.281 18.6-61.41V6.84z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M3063.24 6.84l-7.03 31.25h-1.56c-11.06-13.75-21.99-23.09-32.81-27.969-10.86-4.89-24.54-7.351-41.1-7.351-21.68 0-38.67 5.671-50.94 17.03-12.3 11.329-18.43 27.391-18.43 48.13 0 44.57 35.19 67.89 105.62 70l37.19 1.41v12.961c0 16.758-3.59 29.109-10.78 37.039-7.19 7.89-18.75 11.871-34.69 11.871-11.56 0-22.58-1.801-32.97-5.313-10.31-3.437-20-7.308-29.06-11.558l-11.09 27.031c11.13 5.93 23.2 10.539 36.25 13.899 13.12 3.32 26.09 5 38.9 5 26.56 0 46.29-5.891 59.22-17.649 12.89-11.801 19.38-30.473 19.38-56.101V6.84zm-74.37 25c20.19 0 36.01 5.39 47.5 16.25 11.56 10.93 17.34 26.398 17.34 46.398v19.852l-32.34-1.41c-25.24-.942-43.6-4.961-55.16-12.032-11.56-7.109-17.34-18.238-17.34-33.437 0-11.48 3.47-20.27 10.47-26.41 7.07-6.172 16.91-9.211 29.53-9.211z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M3245.43 2.77c-31.68 0-56.33 9.69-73.91 29.07-17.61 19.45-26.4 47.379-26.4 83.75 0 36.75 8.9 65.07 26.72 85 17.81 20 43.16 30 76.09 30 10.7 0 21.29-1.102 31.72-3.289 10.51-2.18 19.14-5 25.94-8.43l-10.94-30c-18.56 6.867-34.38 10.309-47.5 10.309-22.11 0-38.44-6.989-49.06-20.942-10.55-13.859-15.79-34.648-15.79-62.34 0-26.558 5.24-46.879 15.79-60.937 10.62-14.063 26.25-21.09 46.87-21.09 19.38 0 38.36 4.25 57.03 12.809V14.648c-15.23-7.886-34.06-11.878-56.56-11.878z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M3390.39 6.84h-36.09v219.84h36.09zm-38.91 279.211c0 8.008 2.04 13.789 6.1 17.347 4.06 3.633 9.1 5.473 15.15 5.473 5.63 0 10.51-1.84 14.69-5.473 4.14-3.558 6.25-9.339 6.25-17.347 0-7.813-2.11-13.59-6.25-17.34-4.18-3.75-9.06-5.621-14.69-5.621-6.05 0-11.09 1.871-15.15 5.621-4.06 3.75-6.1 9.527-6.1 17.34z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M3650.94 117.148c0-35.937-9.06-64.027-27.19-84.218-18.13-20.121-43.24-30.16-75.31-30.16-19.92 0-37.5 4.609-52.82 13.91-15.31 9.261-27.14 22.582-35.46 40-8.25 17.379-12.35 37.539-12.35 60.468 0 35.704 8.91 63.512 26.72 83.442 17.89 20 43.01 30 75.31 30 30.94 0 55.51-10.16 73.75-30.469 18.21-20.32 27.35-47.973 27.35-82.973zm-165.94 0c0-27.617 5.39-48.597 16.25-62.968 10.94-14.38 27.03-21.559 48.28-21.559 20.94 0 36.88 7.18 47.81 21.559 10.94 14.37 16.41 35.351 16.41 62.968 0 27.582-5.55 48.364-16.56 62.34-10.94 13.953-27.03 20.942-48.28 20.942-42.62 0-63.91-27.77-63.91-83.282zm37.66 143.122c5.07 6.449 11.48 15.55 19.22 27.351 7.81 11.758 14.41 22.848 19.84 33.277h43.44v-4.218c-5.94-8.559-15.12-19.301-27.5-32.192-12.43-12.929-22.82-22.648-31.25-29.218h-23.75z"
        ></path>
        <path
          fill="#27477e"
          fillRule="nonzero"
          d="M3857.93 6.84V147.3c0 17.898-3.98 31.25-11.88 40-7.93 8.75-20.39 13.129-37.34 13.129-22.5 0-38.87-6.25-49.06-18.75-10.24-12.5-15.31-32.809-15.31-60.942V6.84h-36.1v219.84h29.06l5.32-30h2.03c6.76 10.699 16.29 19.058 28.59 25 12.27 5.941 25.82 8.91 40.63 8.91 26.95 0 46.95-6.57 60-19.692 13.12-13.046 19.68-33.359 19.68-60.937V6.84z"
        ></path>
      </g>
    </g>
  </SVG>
);

export default ReinventaLogo;
